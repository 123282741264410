<template>
    <div class="container mx-auto">
        <h1>{{ msg }}</h1>
        <el-upload
            class="upload-demo"
            drag
            :before-upload="beforeAvatarUpload"
            multiple
        >
            <el-icon class="el-icon--upload"><upload-filled /></el-icon>
            <div class="el-upload__text text-xl">
                <div> 拖曳HEIC檔到這 or <em> 直接點擊上傳</em></div>
                <div> Drop HEIC file or <em> Click here</em></div>
            </div>
            <template #tip>
                <div class="el-upload__tip text-xl">
                    <div class="slider-demo-block sm:w-60 ">
                        <span class="demonstration">轉檔品質/Quality : {{quality}}%</span>
                        <el-slider :min="30" v-model="quality" />
                    </div>
                </div>
            </template>
        </el-upload>
    </div>
</template>

<script>
import heic2any from "heic2any";
import { ref } from 'vue'

function saveFile(blob, filename) {
	if (window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, filename);
	} else {
		var a = document.createElement("a");
		document.body.appendChild(a);
		var url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = filename;
		a.click();
		setTimeout(function () {
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		}, 0);
	}
}

export default {
    name: "HelloWorld",
    props: {
        msg: String,
    },
    setup() {
        
        const quality = ref(95)
        const beforeAvatarUpload = (rawFile) => {
            if (rawFile.type == 'image/heic') {
                console.log(rawFile)
                const blob = new Blob([rawFile],{type: 'image/heic'})

                heic2any({
                    blob,
                    toType: "image/jpeg",
                    quality: quality.value/100,
                }).then(function (resultBlob) {
                    saveFile(resultBlob, rawFile.name.replace(".heic","") + ".jpeg");
                })
                return false
            }else{

                return false
            }
        }

        return {
            beforeAvatarUpload,
            quality
        }
    }
};
</script>

